import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/vue-element-ui';
import './permission';
import './element';
import $ from 'jquery'

import Message from './utils/message';
/** 引入element ,必须有 */
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
window.jQuery = $
window.$ = $
Vue.use(ElementUI);
Vue.prototype.$message = Message;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
