import Vue from 'vue';
import 'element-ui/lib/theme-chalk/index.css';
import {
  Button,
  Input,
  InputNumber,
  Carousel,
  CarouselItem,
  Table,
  TableColumn,
  Radio,
  RadioGroup,
  Select,
  Option,
  Pagination,
  CheckboxGroup,
  MessageBox,
  Checkbox,
  Dialog,
  Form,
  FormItem,
  Tree,
} from 'element-ui';

// eslint-disable-next-line newline-per-chained-call
Vue.use(Button).use(Input).use(Carousel).use(CarouselItem).use(Table).use(TableColumn);
Vue.use(Radio).use(RadioGroup).use(Select).use(Option);
Vue.use(Pagination).use(Checkbox).use(Dialog).use(Form).use(FormItem).use(Tree);
Vue.use(InputNumber).use(CheckboxGroup);

Vue.prototype.$confirm = MessageBox;
