import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/layout/main.vue';

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/login/register.vue'),
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: () => import('../views/login/resetPassword.vue'),
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
        {
            path: 'home',
            name: 'Home',
            component: () => import('@/views/home/index.vue'),
            meta: { title: 'Home' },
          },
      {
        path: 'index',
        name: 'Index',
        component: () => import('@/views/index/index.vue'),
        meta: { title: 'Index' },
      },
      {
        path: 'userInfo',
        name: 'UserInfo',
        component: () => import('@/views/userInfo/index.vue'),
        meta: { title: 'UserInfo' },
      },
      {
        path: 'majorInfo',
        name: 'MajorInfo',
        component: () => import('@/views/majorInfo/index.vue'),
        meta: { title: 'MajorInfo' },
      },
      {
        path: 'collegeInfo',
        name: 'CollegeInfo',
        component: () => import('@/views/collegeInfo/index.vue'),
        meta: { title: 'CollegeInfo' },
      },
      {
        path: 'programme',
        name: 'Programme',
        component: () => import('@/views/programme/index.vue'),
        meta: { title: 'Programme' },
      },
      {
        path: 'wallet',
        name: 'Wallet',
        component: () => import('@/views/wallet/index.vue'),
        meta: { title: 'Wallet' },
      },
    ],
  },
  {
    path: '/demo',
    name: 'ComponentsDemo',
    component: () => import('../views/componentsDemo.vue'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
