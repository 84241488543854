import axios from 'axios';
import { Loading } from 'element-ui';
import VueRouter from 'vue-router';
// import Message from './message';
import router from '../router/index';

let loading = null;
let respTime = '';

const service = axios.create({
  baseURL: '/api/',
//   baseURL: 'http://52.131.37.58:8997/',
});

const route = new VueRouter({
  // mode: 'hash',
  base: process.env.BASE_URL,
  router,
});

service.interceptors.request.use(
  (config) => {
    if (config.url !== '/pay/queryWx' && !config.loading) {
      loading = Loading.service({
        fullscreen: true,
        text: '正在加载，请稍候！',
        spinner: 'el-icon-loading',
        background: 'rgba(0,0,0,0.7)',
      });
    }
    // eslint-disable-next-line no-param-reassign
    config.headers['Cache-Control'] = 'no-cache';
    // eslint-disable-next-line no-param-reassign
    config.headers.Pragma = 'no-cache';
    if (sessionStorage.getItem('sessionId')) {
      // eslint-disable-next-line no-param-reassign
      config.headers.sessionId = sessionStorage.getItem('sessionId');
    }
    console.info(respTime, '====', (new Date().getTime() - Number(respTime)));
    if (respTime && (new Date().getTime() - Number(respTime)) <= 1000) {
      return null;
    }
    return config;
  },
  (error) => {
    if (loading) loading.close();
    return Promise.reject(error);
  },
);

service.interceptors.response.use(
  (response) => {
    if (loading) loading.close();
    // eslint-disable-next-line eqeqeq
    if (response.data.errorCode == 1002 || response.data.errorCode == 1001
      // eslint-disable-next-line eqeqeq
      || response.data.errorCode == 1003) {
      if (!respTime) {
        respTime = new Date().getTime();
        // Message.messageError(response.data.errorMsg);
        route.push('/login');
        // eslint-disable-next-line no-self-assign
        window.location.href = window.location.href;
        return Promise.reject(response.data.errorMsg);
      }
    }
    respTime = '';
    const res = response.data;
    return res;
  },
  // eslint-disable-next-line no-unused-vars
  (error) => {
    if (loading) loading.close();
    if (error.response) {
      return Promise.reject(error.response);
    }
    return {
      message: '请稍后重试',
    };
  },
);

export default service;
