import Vue from 'vue';
import Vuex from 'vuex';
import { LoginOut } from '@/api/login';
import {
  getToken,
  setToken,
  removeToken,
  getUserId,
  setUserId,
  removeUserId,
  removeUserName,
} from '@/utils/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: getToken(),
    userId: getUserId(),
    userName: '',
  },
  mutations: {
    SET_USERNAME(state, data) {
      state.userName = data;
    },
    SET_USERID(state, data) {
      state.userId = data;
    },
    SET_TOKEN(state, data) {
      state.token = data;
    },
    CLEAR_TOKEN(state) {
      state.token = null;
    },
  },

  actions: {
    login({ commit }, data) {
      const { token, userId, userName } = data;
      return new Promise((resolve) => {
        commit('SET_TOKEN', token);
        commit('SET_USERID', userId);
        commit('SET_USERNAME', userName);
        setToken(token);
        setUserId(userId);
        resolve();
      });
    },
    logout({ commit, state }) {
      return new Promise((resolve, reject) => {
        const params = {
          userId: state.userId,
        };
        LoginOut(params).then((res) => {
          if (res.code === 0) {
            removeToken();
            removeUserId();
            removeUserName();
            commit('SET_USERID', null);
            localStorage.removeItem('user');
          }
          resolve(res);
        }).catch((error) => {
          reject(error);
        });
      });
    },
  },
  modules: {
  },
});
