import request from '@/utils/request';

export function Register(data) {
  return request({
    url: '/user/login/register',
    method: 'post',
    data,
  });
}

export function LoginByPwd(data) {
  return request({
    url: '/user/login/login',
    method: 'post',
    data,
  });
}

export function LoginByCode(data) {
  return request({
    url: '/user/login/loginCode',
    method: 'post',
    data,
  });
}

export function LoginOut(data) {
  return request({
    url: '/user/login/loginOut',
    method: 'post',
    data,
  });
}

// 短信重置密码
export function sysResetPassword(data) {
  return request({
    url: '/user/login/smsResetPassword',
    method: 'post',
    data,
  });
}

// 修改用户密码
export function updatePassword(data) {
  return request({
    url: '/user/login/updatePassword',
    method: 'post',
    data,
  });
}

// 发送验证码
export function sendSms(data) {
  return request({
    url: '/user/sendSms',
    method: 'post',
    data,
  });
}

// 发送验证码
export function getUserKnown() {
  return request({
    url: '/user/getUserKnown',
    method: 'post',
  });
}
