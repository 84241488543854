<template>
  <div id="app">
    <router-view v-if="isReloadAlive"/>
  </div>
</template>

<script>
export default {
  provide(){
    return {
      reload:this.reload
    }
  },
  data(){
    return {
      isReloadAlive:true,
    }
  }
}
</script>


<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #F9F9F9;
}
body, p, ul, li, div, span {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.table /deep/ .el-table--border, .el-table--group {
  border: none;
}
.table /deep/ .el-table--border::after, .el-table--group::after {
  background-color: unset;
}
.table /deep/ .el-table th {
  background-color: #F5F5F5;
}

.el-pagination {
  width: 100%;
  text-align: right;
  margin: 20px 0;
}

.base-box {
  max-width: 1400px;
}

#base-swiper {
  max-width: 1400px;
}
/*
  滚动条样式优化
----------------------------------------------------------*/
::-webkit-scrollbar {
  width: 7px;
  height: 4px;
  background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb {
    box-shadow: 0px 0px 8px 0px rgba(238, 238, 238, 0.60) inset;
     background-color: #409EFF;
    border-radius: 3px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
::-webkit-scrollbar-corner{
  background:rgba(1, 31, 74, 0.40);
}
</style>
