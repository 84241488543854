<template>
  <div class="main mainScroll">
    <div style="height: 93px;">
    <div class="topBar">
      <div class="title">
        <img src="../assets/images/assistLogo.png"
             style="width: 24px;vertical-align: middle;border-radius: 2px;margin-right: 2px">
        山东省高考志愿一点通
      </div>
      <div class="statusBar">
        <span class="userName">Hi, {{ userName }}</span>
        <img class="login-status" src="@/assets/images/login_out@3x.png"
             @click="logoutDialogVisible=true">
      </div>
    </div>
    <div class="navBar" :style="$route.name === 'Home' ? 'background:#FFFFFF80' : ''">
      <ul>
        <li style="height:45px;" :class="$route.name === 'Home' ? 'activate' : ''" @click="routeJump('home')">
          <img class="icon" src="@/assets/images/nav_home@3x.png">
          志愿填报
        </li>
        <li style="height:45px;" :class="$route.name === 'UserInfo' ? 'activate' : ''" @click="routeJump('userInfo')">
          <img class="icon" src="@/assets/images/nav_userInfo@3x.png">
          个人信息
        </li>
         <li style="height:45px;" :class="$route.name === 'MajorInfo' ? 'activate' : ''" @click="routeJump('majorInfo')">
          <img class="icon" src="@/assets/images/nav_userInfo@3x.png">
          专业介绍
        </li>
         <li style="height:45px;" :class="$route.name === 'CollegeInfo' ? 'activate' : ''" @click="routeJump('collegeInfo')">
          <img class="icon" src="@/assets/images/nav_userInfo@3x.png">
          大学信息
        </li>
        <li style="height:45px;" :class="$route.name === 'Programme' ? 'activate' : ''" @click="routeJump('programme')">
          <img class="icon" src="@/assets/images/nav_programme@3x.png">
          我的方案
        </li>
        <li style="height:45px;" :class="$route.name === 'Wallet' ? 'activate' : ''" @click="routeJump('wallet')">
          <img class="icon" src="@/assets/images/nav_wallet@3x.png">
          我的账户
        </li>
        <li style="height:45px;" :class="$route.name === 'Index' ? 'activate' : ''" @click="routeJump('index')">
          <img class="icon" src="@/assets/images/nav_home@3x.png">
          关于我们
        </li>
      </ul>
    </div>
    </div>
    <div class="router-div">
      <router-view/>
    </div>
    <base-dialog
      :baseDialogVisible="logoutDialogVisible"
      :baseDialogTitleShow="false"
      @closeDialog="closeLogoutDialog"
    >
      <div>
        <img src="@/assets/images/icon_tip@3x.png" alt style="width: 100px; height: 100px;">
        <p>是否退出当前账号</p>
      </div>
      <span class="dialog-footer" slot="dialog-footer">
        <el-button @click="closeLog">取消</el-button>
        <el-button type="primary" @click="loginout">确认</el-button>
      </span>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/BaseDialog/index.vue';

export default {
  name: 'Main',
  components: {
    BaseDialog,
  },
  data() {
    return {
      userName: '',
      logoutDialogVisible: false,
    };
  },
  computed: {
    UserName() {
      return this.$store.state.userName;
    },
  },
  watch: {
    UserName() {
      this.userName = this.$store.state.userName;
    },
  },
  mounted() {
    if (localStorage.getItem('user')) {
      this.userName = JSON.parse(localStorage.getItem('user')).userName;
    }
  },
  methods: {
    closeLog(){
      this.logoutDialogVisible = false
      // this.$router.push('/home');
    },
    closeLogoutDialog() {
      this.logoutDialogVisible = false;
    },
    routeJump(key) {
      let url = '/';
      url = key;
      this.$router.push(`/${url}`);
    },
    loginout() {
      this.$store.dispatch('logout').then((res) => {
        if (res.code === 0) {
          if (sessionStorage.getItem('sessionId')) {
            sessionStorage.removeItem('sessionId');
          }
          this.$router.push('/login');
        } else {
          this.$message.messageError(`退出失败, ${res.message}`);
        }
      }).catch((error) => {
        this.$message.messageError(`退出失败, ${error.status}`);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .mainScroll{
    position: absolute;
    height: 100%;
    width: 100%;
    // overflow: auto;
  }
  .router-div {
    height: calc(100% - 93px);
    width: 100%;
    overflow: auto;
  }
  .topBar {
    position: relative;
    width: 100%;
    height: 48px;
    line-height: 48px;
    background-color: #409EFF;
    box-shadow: 0 2px 4px 0 rgba(1, 31, 74, 0.40);

    .title {
      position: absolute;
      left: 13.5%;
      top: 50%;
      transform: translateY(-50%);
      display: inline-block;
      height: 20px;
      line-height: 20px;
      font-size: 20px;
      color: #fff;

    }

    .statusBar {
      position: absolute;
      right: 13.6%;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;

      .avatar {
        width: 20px;
        height: 20px;
        border: 1px solid #fff;
        border-radius: 50%;
      }

      .userName {
        height: 18px;
        line-height: 18px;
        font-size: 18px;
        padding-left: 12px;
        padding-right: 15px;
        margin-right: 17px;
        color: rgba(255, 255, 255, 0.80);
        border-right: 1px solid rgba(238, 238, 238, 0.60);
      }

      .login-status {
        width: 18px;
        height: 18px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .navBar {
    position: relative;
    z-index: 5;
    height: $autoHeight;
    background: #FFFFFF;
    height: 45px;
    box-shadow: 0 4px 12px 0 rgba(1, 31, 74, 0.40);
    display: flex;
    justify-content: center;

    ul {
      list-style: none;
      // position: absolute;
      // right: 13.5%;
    }

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      max-width: 150px;
      min-width: 8.5vw;
      height: $autoHeight;
      font-size: 16px;
      color: #409EFF;

      .icon {
        width: 28px;
        height: 28px;
        margin-right: 8px;
      }

      &:hover {
        cursor: pointer;
        background: #409EFF;
        color: #FFFFFF;
      }
    }
  }

  .activate {
    background: #409EFF !important;
    color: #FFFFFF !important;
  }

  .dialog-footer {
    .el-button {
      width: 120px;
    }
  }
</style>
