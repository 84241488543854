<template>
  <el-dialog
    :visible.sync="baseDialogVisible"
    :width="baseDialogWidth"
    :before-close="handleClose"
    :show-close="baseDialogShowClose"
    :close-on-click-modal="baseDialogCloseOnClickModal"
    :modal-append-to-body="modalAppend"
  >
    <span class="dialog-title" slot="title" v-if="baseDialogTitleShow">
      <slot name="dialog-title" />
    </span>
    <slot />
    <span slot="footer" class="dialog-footer" v-if="baseDialogShowFoot">
    <el-button @click="handleCancel">取消</el-button>
    <el-button type="primary" @click="handleNext">同意</el-button>
  </span>
    <span slot="footer" v-else>
      <slot name="dialog-footer" />
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'BaseDialog',
  props: {
    modalAppend:{
      type:Boolean,
      default:true
    },
    baseDialogVisible: {
      type: Boolean,
      default: false,
    },
    baseDialogTitleShow: {
      type: Boolean,
      default: true,
    },
    baseDialogWidth: {
      type: String,
      default: '30%',
    },
    baseDialogShowClose: {
      type: Boolean,
      default: false,
    },
    baseDialogCloseOnClickModal: {
      type: Boolean,
      default: false,
    },
    baseDialogShowFoot: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    handleClose() {
      this.$emit('closeDialog');
    },
    handleCancel() {
      this.$emit('readed', false);
    },
    handleNext() {
      this.$emit('readed', true);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  background: #FFFFFF;
  box-shadow: 0 4px 20px 0 rgba(1,31,74,0.40);
  border-radius: 8px;
}

/deep/ .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px solid #EEEEEE;
}

/deep/ .el-dialog__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #EEEEEE;
}
</style>
